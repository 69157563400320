import Main from './Components/Main/index';
import './App.css';
import { UseAppContext } from "./context"

function App() {

  return (
    <section className="main">
      <Main />
    </section>
  );
}

export default App;
